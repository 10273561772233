// TypeAutoComplete.js
import React, { forwardRef, useEffect, useRef, useState } from 'react';
import { Icon, ListItem } from '@startlibs/components';
import styled from 'styled-components';
import _ from 'lodash/fp';
import {  useFormValue } from '@startlibs/form';
import { getColor, media } from '@startlibs/utils';

const WithLabelAndShadowInput = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  input {
    width: 100%;
    font-size: 14px;
    border: 1px solid #d2d2d2;
    border-radius: 6px;
    outline: 0;
    outline: none;
    color: #3c3c3c;
    background: white;
    position: relative;
    height: 3rem;
    padding: 0 0.7rem;
    padding-right: 3rem;
    overflow: auto;
    border-radius: 6px;
    ::-webkit-input-placeholder {
      color: rgba(0,0,0,0.3);
    }
    ::-moz-placeholder {
      color: rgba(0,0,0,0.3);
    }
    :-moz-placeholder {
      color: rgba(0,0,0,0.3);
    }
    :-ms-input-placeholder {
      color: rgba(0,0,0,0.3);
    }
    :focus {
      border-color: #b4b4b4;
      box-shadow: 0px 0px 0px 2px rgba(0, 0, 0, 0.1);
    }
  }
  label {
    display: inline-block;
    font-size: 14px;
    margin-bottom: .5rem;
    font-weight: 600;
    color: #3C3C3C;
  }
`;

const Mandatory = styled.span`
    color: #c3282d;
    margin-left: 1px;
    vertical-align: top;
    line-height: 100%;
    margin-top: -1px;
`;

const AutoCompleteMenu = styled.ul`
  position: absolute;
  background: white;
  border: 1px solid #d2d2d2;
  border-radius: 0px 0px 6px 6px;
  width: 100%; /* Match input width */
  max-height: 200px;
  overflow-y: auto;
  margin-top: -4px;
  z-index: 10;
  padding: 0; /* Remove default padding for consistent look */
  box-sizing: border-box;
`;

const AutoCompleteLi = styled(ListItem)`
  background-color: ${(props) => (props.isHighlighted ? '#008bd2' : 'white')}; /* Highlight selected item */
  padding: 11px; /* Add padding for space around text */
  font-size: 13px; /* Set text size */
  cursor: pointer;
  color: ${(props) => (props.isHighlighted && 'white'  )};
  &:not(:last-child) {
    border-bottom: 1px solid #e0e0e0; /* HR-like border between items */
  }
`;



const DropdownIconContainer = styled(Icon)`
  position: absolute;
  right: 0;
  bottom: 0;
  width: 3rem;
  height: 3rem;
  top: 26px;
  ${media.mobile`
    height: 42px;
  `}
  ${Icon} {
    position: absolute;
    width: 2rem;
    height: 2rem;
    line-height: 2rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    background: rgba(0,0,0,0.1);
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    color: ${getColor('gray90')};
    :hover, :active {
      background: rgba(0,0,0,0.15);
    }
  }
`

const BelowDescText = styled.div`
  color: rgba(0, 0, 0, 0.4);
  display: block;
  font-size: 12px;
  font-weight: 400;
  margin: 0.5rem 0 0;
`


export const TypeAutoComplete = forwardRef(({
    label,
    path,
    placeholder,
    initOptions = [],
    tabIndex = 0,
    disabled = false,
    mandatory = false,
    hasErrors = false,
    bellowDescText = '',
    ...props
}, ref) => {

    const [value, setValue] = useFormValue(path);
    const inputRef = useRef(null);
    const dropdownRef = useRef(null);
    const [filteredOptions, setFilteredOptions] = useState([]);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(-1);
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [isKeyboardNavigating, setIsKeyboardNavigating] = useState(false);
    // Conditions for showing the errors must be set here as well.
    const hasError = hasErrors && (
      (value === '' && mandatory) || 
      (value !== '' && value.length > 120)
    );

    const openDropdown = () => {
        setFilteredOptions(
            initOptions.filter(option => option.toLowerCase().includes((value || '').toLowerCase()))
        );
        setDropdownOpen(true);
        setHighlightedIndex(0); // Highlight the first item when opening
        setIsKeyboardNavigating(true); // Initialize keyboard navigation
    };

    const openDropdownWithAllOptions = () => {
        setFilteredOptions(initOptions); // Show all options on icon click
        setDropdownOpen(true);
        setHighlightedIndex(0); // Highlight the first item when opening
        setIsKeyboardNavigating(true); // Initialize keyboard navigation
    };

    const handleInputChange = (e) => {
        const input = e.target.value;
        setValue(input);

        if (input === '') {
            setDropdownOpen(false); // Close dropdown when input is empty
        } else {
            openDropdown();
        }
    };

    const handlePaste = (e) => {
      e.preventDefault(); // Prevent the default paste action
      const pastedValue = e.clipboardData.getData('text');
      setValue(pastedValue);
      setDropdownOpen(false); // Close dropdown on paste
  };

    const handleSelectOption = (option) => {
        setValue(option);
        setDropdownOpen(false); // Close dropdown on selection
    };

    const handleKeyDown = (e) => {
        
        if (e.key === 'ArrowDown') {
            e.preventDefault();
            setIsKeyboardNavigating(true); // Engage keyboard navigation mode
            setHoveredIndex(null); // Clear hover highlight when navigating with keyboard
            if (!dropdownOpen) {
                openDropdown(); // Open dropdown if it's closed
            } else {
                setHighlightedIndex((prevIndex) =>
                    prevIndex < filteredOptions.length - 1 ? prevIndex + 1 : 0
                );
            }
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setIsKeyboardNavigating(true); // Engage keyboard navigation mode
            setHoveredIndex(null); // Clear hover highlight when navigating with keyboard
            setHighlightedIndex((prevIndex) =>
                prevIndex > 0 ? prevIndex - 1 : filteredOptions.length - 1
            );
        } else if (e.key === 'Enter'){
            // e.preventDefault(); // Prevent default form submission
            if (dropdownOpen && highlightedIndex >= 0 && filteredOptions && filteredOptions.length > 0) {
              e.preventDefault()
              handleSelectOption(filteredOptions[highlightedIndex]);
          }
        } else if (e.key === 'Escape') {
            e.preventDefault();
            e.stopPropagation();
            setDropdownOpen(false);
        } else if (e.key === 'Tab') {
            // Do not prevent default to maintain normal tab navigation
            if (dropdownOpen && highlightedIndex >= 0 && filteredOptions && filteredOptions.length > 0) {
                handleSelectOption(filteredOptions[highlightedIndex]);
            }
            // Close dropdown when tabbing away
            setDropdownOpen(false);

        }
    };

    const handleClickOutside = (e) => {
        if (dropdownRef.current && !dropdownRef.current.contains(e.target) && !inputRef.current.contains(e.target)) {
            setDropdownOpen(false);
        }
    };

    const handleMouseEnter = (index) => {
        if (!isKeyboardNavigating) {
            setHighlightedIndex(-1); // Clear keyboard highlight when hovering
            setHoveredIndex(index); // Set hover highlight
        }
    };

    const handleMouseMove = () => {
        if (isKeyboardNavigating) {
            setIsKeyboardNavigating(false); // Switch back to mouse navigation
            setHighlightedIndex(-1); // Clear keyboard highlight on mouse move
        }
    };

    const handleMouseLeave = () => {
        setHoveredIndex(null); // Remove hover highlight when leaving item
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        if (highlightedIndex >= 0 && dropdownRef.current) {
            const listItem = dropdownRef.current.querySelectorAll('li')[highlightedIndex];
            if (listItem) listItem.scrollIntoView({ block: 'nearest' });
        }
    }, [highlightedIndex]);

    return (
      <WithLabelAndShadowInput ref={dropdownRef}>
        <label>{label}{mandatory && <Mandatory>*</Mandatory>}</label>
        <input
            type="text"
            value={value || ''}
            onChange={handleInputChange}
            onFocus={openDropdown} // Open dropdown on input focus
            onPaste={handlePaste} // Close dropdown on paste
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            ref={inputRef}
            tabIndex={tabIndex}
            disabled={disabled}
            style={hasError ? {border: '1px solid #C3282D'} : {}}
        />
        <DropdownIconContainer onClick={openDropdownWithAllOptions}>
          <Icon icon="arrow-down" /> {/* Assuming Icon component handles icons */}
        </DropdownIconContainer>
        {dropdownOpen && filteredOptions.length > 0 && (
          <AutoCompleteMenu>
            {filteredOptions.map((option, index) => (
              <AutoCompleteLi
                key={index}
                isHighlighted={index === (isKeyboardNavigating ? highlightedIndex : hoveredIndex)}
                onClick={() => handleSelectOption(option)}
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseMove={handleMouseMove} // Detect mouse movement to reset navigation mode
                onMouseLeave={handleMouseLeave}
              >
                {option}
              </AutoCompleteLi>
            ))}
          </AutoCompleteMenu>
        )}
        {bellowDescText && bellowDescText?.length > 0 && <BelowDescText>{bellowDescText}</BelowDescText>}
      </WithLabelAndShadowInput>
    );
});